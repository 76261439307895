



























import {Component, Vue,} from 'vue-property-decorator';
import NotificationBarCountDownTimer from './NotificationBarCountDownTimer.vue';
import InitialOffer from '../subscription/InitialOffer.vue';

@Component({
  components: {NotificationBarCountDownTimer},
})
export default class OfferNotificationBar extends Vue {

  mounted() {
    this.$store.commit('set_initial_offer_code', true);
  }

  openOfferModal() {
    // @ts-ignore
    this.modalOpen = true;
    this.$buefy.modal.open({
      component: InitialOffer,
      // @ts-ignore
      customClass: 'round-corners',
      width: '85vw',
      parent: this,
      canCancel: false,
    });
  }

  routeToCheckout() {
    this.$router.push({name: 'workspace-checkout', params: {productId: "648f3a46-d5cc-48da-8a3c-a0c4e0f50a87", billingPeriod: "monthly"}});

  }

  close() {
    this.$store.commit('set_show_initial_offer_notification_bar', false)
  }
}
