





































import {Component, Vue} from 'vue-property-decorator';
import UserStatus from "@/components/user/UserStatus.vue";

@Component({
  components: {},
})
export default class UserLayoutMenu extends Vue {
  get me() {
    return this.$store.state.me;
  }

  openStatusModal() {
    this.$buefy.modal.open({
      component: UserStatus,
      // @ts-ignore
      customClass: 'is-paddingless',
      width: '520px',
      parent: this,
    });
  }

}
