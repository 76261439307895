
















































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class WorkspaceSidebarLoader extends Vue {

  @Prop({ required: false, default: false })
  stale!: boolean;

}
